<template scoped>
  <div>
    <el-form-item
      :rules="rules.save"
      label="Save"
      prop="save.type"
      :style="{ marginTop: '10px' }"
    >
      <el-select
        @change="type_change"
        v-model="logic.save.type"
        :style="{ width: '100%' }"
        placeholder="Select the Action"
      >
        <el-option
          v-for="item in supportSave"
          :key="item.value"
          :label="item.name"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </el-form-item>

    <component :is="content" :logic="logic" :endpoint="endpoint"></component>
  </div>
</template>

<script>
export default {
  props: {
    logic: Object,
    endpoint: String,
  },
  created: function () {
    if (this.logic.save.type) {
      this.type_change(this.logic.save.type);
    }
  },
  computed: {},
  data: function () {
    return {
      content: null,
      supportSave: [
        {
          name: "Blood Pressure Record",
          value: "BloodPressure",
        },
        {
          name: "Blood Glucose Record",
          value: "BloodGlucose",
        },
        {
          name: "ECG Record",
          value: "ECG",
        },
        {
          name: "Step Record",
          value: "Step",
        },
        {
          name: "Heart Rate Record",
          value: "HeartRate",
        },
        {
          name: "Sleep Record",
          value: "Sleep",
        },
        {
          name: "Teeth Record",
          value: "Teeth",
        },
      ],
      rules: {
        save: [{ validator: this.validator_save, trigger: "blur" }],
      },
    };
  },
  methods: {
    validator_save: function (rule, value, callback) {
      if (!value) {
        callback(new Error("the save type should not be empty"));
      } else {
        callback();
      }
    },
    type_change: function (val) {
      switch (val) {
        case "BloodPressure":
          this.content = () => import("./BloodPressure/Panel");
          break;
        case "HeartRate":
          this.content = () => import("./HeartRate/Panel");
          break;
        default:
          this.content = null;
          break;
      }
    },
  },
};
</script>